<template>
    <footer class="w-100 mt-5 position-relative">
      sdefgegweg
    </footer>
</template>

<script>
export default {
	name: 'Vfooter',
	created() {
		
	}
};
</script>
<style scoped>

</style>