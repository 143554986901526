<template>
	<div id="app" class="bg-light">
		<nav-bar v-if="!maintenanceMode"></nav-bar>
		<b-toast v-model="show401Toast" @hidden="show401Toast = false" variant="danger" solid title="Error - 401">
			Por favor faça login antes de proceder.
		</b-toast>
		<transition :duration="{ enter: 250, leave: 150 }"
				enter-active-class="animated fadeIn faster"
				leave-active-class="animated fadeOut faster" 
				mode="out-in">
			<router-view />
		</transition>
		<transition :duration="{ enter: 5000, leave: 150 }"
			enter-active-class="animated fadeIn faster"
			leave-active-class="animated fadeOut faster" 
			mode="out-in">
			<vfooter v-if="$route.fullPath" :key="$route.fullPath"/>
		</transition>
	</div>
</template>

<script>
	import NavBar from '@/components/NavBar'
	import Vfooter from '@/components/Vfooter'

	export default {
		name: 'App', 
		components: {
			NavBar,
			Vfooter
		},
		data() {
			return {
				maintenanceMode: process.env.VUE_APP_MAINTENANCE_MODE == 1,
				show401Toast: false,
			}
		},
		watch: {
			'$route.query': function(new_val) {
				if(new_val['show-toast-401']) {
					// get rid of the query string
					this.$logout(false);
					this.$router.replace({name: this.$route.name, params: this.$route.params});
					this.show401Toast = true;
				}
			},
		},
		created() {
			const { fetch: originalFetch } = window;
			window.fetch = async (...args) => {
					let [resource, config, contentType, setAuthHeader ] = args;
					// request interceptor here

					if(!('headers' in config)) {
						config['headers'] = {};
					}
					if(this.$user && setAuthHeader !== false) {
						config['headers']['Authorization'] = `Bearer ${this.$user.token.access_token}`;
					}
					if(contentType === undefined) {
						config['headers']['Content-Type'] = 'application/json';
					}

					const response = await originalFetch(resource, config);
					// response interceptor here
					if(response.status === 401 && this.$route.meta.private) {
						const errResp = {status: response.status, body: await response.json()};
						this.$logout(false);
						if(!this.show401Toast) {
							this.show401Toast = true;
						}
						this.$router.push({name: 'home'});
						return errResp;
					}
					return {status: response.status, body: await response.json()};
			};
		}
	}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&display=swap');
@import "@/assets/css/_custom.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import './assets/css/font-awesome.css';
@import './assets/css/animate_3.7.2.min.css';

[v-cloak] {
	display: none;
}

body {
	font-family: 'Roboto' !important;
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}
a:hover {
	text-decoration: none;
}
.cursor-pointer {
	cursor: pointer;
}
.container-fluid.main-content, .container.main-content {
	padding-top: 120px;
}
.bg-quadrifoil-light {
	background-color: #EAF1EE;
}
footer{
	height: 400px;
	background-color: $darkgreen;
}
</style>
