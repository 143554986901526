import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // { path: '/', redirect: { name: 'apply' } },
  // {
  //   path: '/admin',
  //   // beforeEnter(to, from, next) {
  //   beforeEnter() {
  //     // Put the full page URL including the protocol http(s) below
  //     window.location.replace(process.env.VUE_APP_API_URL+ '/en/admin/login');
  //   }
  // },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "HomeView" */ '../pages/HomeView.vue'),
  },
  {
    path: '/activities',
    name: 'activities',
    component: () => import(/* webpackChunkName: "ActivitiesView" */ '../pages/ActivitiesView.vue')
  },
  {
    path: '/travel-talks',
    name: 'travel-talks',
    component: () => import(/* webpackChunkName: "ActivitiesView" */ '../pages/ActivitiesView.vue')
  },
  {
    path: '/termos-condicoes',
    name: 'termos-condicoes',
    component: () => import(/* webpackChunkName: "TermsConditionsView" */ '../pages/TermsConditionsView.vue')
  },
  {
    path: '/my-profile',
    name: 'my-profile',
    component: () => import(/* webpackChunkName: "MyProfileView" */ '../pages/MyProfileView.vue'),
    meta: {
      private: true,
    }
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "AboutUsView" */ '../pages/AboutUsView.vue'),
  },
  {
    path: '/activity/:id/:slug?',
    name: 'activity',
    component: () => import(/* webpackChunkName: "ActivityView" */ '../pages/ActivityView.vue'),
  },
  {
    path: '/edit-activity/:id',
    name: 'edit-activity',
    component: () => import(/* webpackChunkName: "EditAtivityView" */ '../pages/EditAtivityView.vue'),
    meta: {
      private: true,
      role: 'operator',
      askServerACL: process.env.VUE_APP_API_URL+ '/operator/check-acl',
    }
  },
  {
    path: '/add-activity',
    name: 'add-activity',
    component: () => import(/* webpackChunkName: "EditAtivityView" */ '../pages/EditAtivityView.vue'),
    meta: {
      private: true,
      role: 'operator',
      askServerACL: process.env.VUE_APP_API_URL+ '/operator/check-acl',
    }
  },
  {
    path: '/my-activities',
    name: 'my-activities',
    component: () => import(/* webpackChunkName: "MyActivitiesView" */ '../pages/MyActivitiesView.vue'),
    meta: {
      private: true,
      role: 'operator',
      askServerACL: process.env.VUE_APP_API_URL+ '/operator/check-acl',
    }
  },

  { path: '*', redirect: { name: 'home' } },
  // {
  //   path: '/chat/:room_id/:passcode',
  //   name: 'chat',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../pages/ChatView.vue')
  // }
]

const routeMaintenance = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "MaintenanceModeView.vue" */ '../pages/MaintenanceModeView.vue'),
  },
  { path: '*', redirect: { name: 'home' } }
];

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.VUE_APP_BASE_URL,
  routes: (process.env.VUE_APP_MAINTENANCE_MODE === '1') ? routeMaintenance : routes
});

router.beforeEach((to, from, next) => {
  if(to.meta.private) {
    if(localStorage.getItem('user') == null) {
      next({name: 'home', query: {'show-toast-401': 1}});
      return;
    }
    
    const user = JSON.parse(localStorage.getItem('user'));
    if(to.meta.role && to.meta.role !== user.role.guard) {
      next({name: 'home', query: {'show-toast-401': 1}});
      return;
    }
    // extra security in case someone edits local storage on application dev tools tab
    if(to.meta.askServerACL) {
      fetch(to.meta.askServerACL, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.token.access_token}`
        }
      }).then((resp) => {
        if(resp.status === 401 || resp.status === 403) {
          next({name: 'home', query: {'show-toast-401': 1}});
          return;
        }
      });
    }
  }
	next();
});



export default router
