<template>
    <transition name="fade">
        <div class="loader" :class="theme">
            <div>
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                <br>
                loading...
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    name: 'loader',
    props: ['theme']
}
</script>
<style scoped lang="scss">
    @import "@/assets/css/_custom.scss";

    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .loader.dark .lds-ellipsis div {
        background: $primary;
    }
    .loader.light .lds-ellipsis div {
        background: $primary;
    }
    .lds-ellipsis div:nth-child(1) {
            left: 6px;
            animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
            left: 6px;
            animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 26px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 45px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
           transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(19px, 0);
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0
    }
    .loader.light {

    }
    .loader.dark {
        background-color: #29363d;
        opacity: 0.95;
    }
    .loader.light {
        background-color: #ffffff;
        opacity: 0.95;
    }
    .loader {
        color: $primary;
        font-weight: bold;
        position: fixed;
        display: table;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .loader > div {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        margin: auto auto;
    }
</style>