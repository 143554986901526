<template>
	<b-container fluid class="my-navbar w-100 position-fixed" :class="[(scrolled) ? 'scrolled text-dark' : 'not-scrolled text-white']">
		<loader v-if="loading" v-bind:theme="'light'"></loader>
		<b-row>
			<b-col cols="5">
				<router-link :to="{name: 'home'}">
					<img :class="(!scrolled) ? 'invisible': ''" alt="Logo" :src="require('../assets/images/logo.png')" class="position-absolute mt-3">
					<img :class="(scrolled) ? 'invisible': ''" alt="Logo" :src="require('../assets/images/logo-home.png')" class="position-absolute">
				</router-link>
			</b-col>
			<b-col cols="7" class="text-right">
				<router-link :to="{name: 'activities'}" class="my-nav-link text-white d-inline-block font-weight-bold cursor-pointer h4 mr-3">atividades</router-link>
				<router-link :to="{name: 'travel-talks'}" class="my-nav-link text-white d-inline-block font-weight-bold cursor-pointer h4 ml-3 mr-3">travel talks</router-link>
				<b-button v-if="!$user" @click="showModal('login-register-form')" variant="primary" class="d-inline-block font-weight-bold cursor-pointer h4 ml-3 mr-1">Login/Registo</b-button>
				<b-dropdown v-else right :text="$user.name" variant="primary">
					<router-link :to="{name: 'my-profile'}" v-slot="{ navigate }" custom>
						<b-dropdown-item @click="navigate">Perfil<i class="mt-1 float-right fa-solid fa-regular fa-user"></i></b-dropdown-item>
					</router-link>
					<router-link v-if="$user.role.guard === 'operator'" :to="{name: 'my-activities'}" v-slot="{ navigate }" custom>
						<b-dropdown-item @click="navigate">Atividades<i class="mt-1 float-right fa-solid fa-gears"></i></b-dropdown-item>
					</router-link>
					<b-dropdown-item v-on:click="$logout">Sair <i class="mt-1 float-right fa-solid fa-arrow-right-from-bracket"></i></b-dropdown-item>
				</b-dropdown>
			</b-col>
		</b-row>
		<b-modal ref="login-register-form" title-class="mx-auto w-100" hide-footer @shown="setTabModal" @hidden="closeLoginRegisterModal">
			<template #modal-title>
				<b-tabs align="center" v-model="LoginModalTabIndex" justified pills>
					<b-tab title="Login"></b-tab>
					<b-tab title="Registo"></b-tab>
				</b-tabs>
			</template>
			<b-container fluid>
				<b-row v-show="LoginModalTabIndex == 0" v-on:keyup.enter="login">
					<b-col cols="12">
						<label for="login-username" type="email" class="mt-2 mb-0">Email:</label>
						<b-form-input v-model="loginForm.email" id="login-username" type="text"></b-form-input>
					</b-col>
					<b-col cols="12">
						<label for="login-password" class="mt-2 mb-0">Password:</label>
						<b-form-input v-model="loginForm.password" id="login-password" type="password"></b-form-input>
					</b-col>
					<b-col cols="12" class="mt-1">
						<b-form-checkbox
							class="mt-2"
							id="remember_me"
							v-model="loginForm.remember_me"
							:unchecked-value="false"
							switch
						>
							Lembrar-me.
						</b-form-checkbox>
					</b-col>
					<b-col cols="12" class="mt-3">
						<b-alert :show="alertDangerMsg" variant="danger">
							Por favor confirme o seu email antes de proceder. <br>
							<p class="text-primary cursor-pointer mb-0" @click="resendConfirmationEmail">Reenviar email de confirmação.</p>
						</b-alert>
					</b-col>
				</b-row>	
				<b-row v-show="LoginModalTabIndex == 1" v-on:keyup.enter="register">
					<b-col cols="12">
						<label for="register-username" class="mt-2 mb-0">Nome:</label>
						<b-form-input v-model="registerForm.name" id="register-username" type="text" trim></b-form-input>
					</b-col>
					<b-col cols="12">
						<label for="register-email" class="mt-2 mb-0">Email:</label>
						<b-form-input v-model="registerForm.email" id="register-email" type="email" trim></b-form-input>
					</b-col>
					<b-col cols="12">
						<label for="register-password" class="mt-2 mb-0">Password:</label>
						<b-form-input v-model="registerForm.password" id="register-password" type="password"></b-form-input>
					</b-col>
					<b-col cols="12">
						<b-form-group :state="passwordsMatch" invalid-feedback='Passwords não condizem'>
							<label for="register-password-confirm" class="mt-2 mb-0">Confirmar Password:</label>
							<b-form-input v-model="registerForm.password_confirmation" id="register-password-confirm" type="password" :state="passwordsMatch"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12">
						<b-form-group
							label="Registar-me com o tipo:"
							v-slot="{ ariaDescribedby }"
						>
						<b-form-radio-group
							id="btn-radios-2"
							v-model="registerForm.id_role"
							:options="roles"
							:aria-describedby="ariaDescribedby"
							button-variant="outline-primary"
							buttons
						></b-form-radio-group>
						</b-form-group>
					</b-col>
					<b-col cols="12">
						<b-form-checkbox
							id="agree-terms"
							v-model="registerForm.agree"
							:unchecked-value="false"
							switch
						>
							Concordo com os <router-link :to="{name: 'termos-condicoes'}">termos e condições</router-link>.
						</b-form-checkbox>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12" class="text-center mt-4">
						<b-button v-show="LoginModalTabIndex === 0" variant="primary" @click="login">Login</b-button>
						<b-button v-show="LoginModalTabIndex === 1" variant="primary" @click="register">Registar</b-button>
						<b-button variant="secondary" class="ml-2" @click="closeLoginRegisterModal">Cancelar</b-button>
					</b-col>
				</b-row>
			</b-container>
		</b-modal>
	</b-container>
</template>
<script>
import Loader from '@/components/Loader'

export default {
	name: 'NavBar',
	components: {
		Loader
	},  
	data() {
		return {
			loading: false,
			registerOnError: false,
			isIE: false,
			scrolled: false,
			LoginModalTabIndex: 0,
			roles: [],
			registerForm: {password: "", email: "", password_confirmation: "", agree: false, id_role: null, name: ""},
			loginForm: {password: "", email: "", remember_me: false},
			alertDangerMsg: false,
			emailConfirmationUrl: null,
			maskedConfirmationEmail: null
		}
	},
	computed: {
		passwordsMatch() {
			if(this.registerForm.password.length === 0 || this.registerForm.password_confirmation.length === 0) {
				return null;
			}
			return this.registerForm.password === this.registerForm.password_confirmation;
		}
	},
	watch: {
		'$route.name': function() {
			this.onCreate();
			this.closeLoginRegisterModal();
			window.scrollTo(0, 0);
		},
		LoginModalTabIndex: async function(new_val) {
			if(new_val === 1 && this.roles.length === 0)  {
				this.roles = await this.getRoles();
			}
		},
		loading: function(new_val) {
			if(new_val) {
				this.$refs['login-register-form'].hide();
			}
		}
	},
	methods: {
		is_ie() {
			let ua = window.navigator.userAgent;

			// IOS
			if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
				return false;
			}

			if(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
				return false;
			}

			let iDevices = [
				'iPad Simulator',
				'iPhone Simulator',
				'iPod Simulator',
				'iPad',
				'iPhone',
				'iPod'
			];
			
			if (navigator.platform) {
				while (iDevices.length) {
					if (navigator.platform === iDevices.pop()){
						return false;
					}
				}
			}
			

			let msie = ua.indexOf('MSIE ');
			if (msie > 0) {
				// IE 10 or older => return version number
				return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
			}


			let trident = ua.indexOf('Trident/');
			if (trident > 0) {
				// IE 11 => return version number
				let rv = ua.indexOf('rv:');
				return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
			}

			let edge = ua.indexOf('Edge/');
			if (edge > 0) {
				// Edge (IE 12+) => return version number
				return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
			}

			// other browser
			return false;
		},
		handleScroll () {
			this.scrolled = this.has_scrolled();
		},
		has_scrolled: function() {
			if(this.$route.name === 'home') {
				if(this.isIE) {
					return window.pageYOffset >= document.documentElement.clientHeight - 80;
				}
				return window.scrollY >= window.innerHeight - 80;
			}
			return true;
		},
		async resendConfirmationEmail() {
			this.loading = true;
			const resp = await fetch(this.emailConfirmationUrl, {
					method: 'GET',
			});
			if(resp.status === 200) {
				this.$showToast('Email de confirmação enviado.', `Email reenviado para ${this.maskedConfirmationEmail}.`, 'success');
				this.alertDangerMsg = false;
			}
			else {
				this.showModal('login-register-form');
			}
			this.loading = false;
		},
		async getRoles() {
			const resp = await fetch(process.env.VUE_APP_API_URL+ '/roles', {
					method: 'GET',
			});
			return resp.body;
		},
		async login() {
			if(this.loginForm.password == '' || this.loginForm.email == '') {
				this.$showToast('Erro', 'Deve preencher o email e password.', 'danger');
				return;
			}
			this.loading = true;
			this.alertDangerMsg = false;
			this.registerOnError = false;
			const resp = await fetch(process.env.VUE_APP_API_URL+ '/auth/login', {
					method: 'POST',
					body: JSON.stringify(this.loginForm),
			});
			this.loading = false;
			if(resp.status === 200) {
				this.$user = resp.body;
				localStorage.setItem('user', JSON.stringify(this.$user));
				this.closeLoginRegisterModal()
				this.resetForms();
				if(this.$route.query['show-toast-401']) {
					// get rid of the query string if any
					this.$router.replace({name: this.$route.name, params: this.$route.params});
				}
				return;
			}
			if(resp.status === 403) {
				this.alertDangerMsg = true;
				this.emailConfirmationUrl = resp.body.confirmationEndpoint;
				this.maskedConfirmationEmail = resp.body.maskedEmail;
			}
			else {
				this.$showToast(`Erro - ${resp.status}`, resp.body.message, 'danger');
			}
			this.showModal('login-register-form');
		},
		async register() {
			this.registerOnError = false;
			if(!this.registerForm.agree) {
				this.$showToast('Erro', 'Deve concordar com os termos e condições.', 'danger');
				this.registerOnError = true;
			}
			if(!this.passwordsMatch) {
				this.$showToast('Erro', 'Passwords não condizem.', 'danger');
				this.registerOnError = true;
			}
			if(!this.registerForm.id_role) {
				this.$showToast('Erro', 'Selecione o tipo de registo que pretende.', 'danger');
				this.registerOnError = true;
			}
			if(this.registerOnError) {
				return;
			}
			this.loading = true;
			const resp = await fetch(process.env.VUE_APP_API_URL+ '/auth/register', {
					method: 'POST',
					body: JSON.stringify(this.registerForm),
			});
			if(resp.status === 201) {
				this.$showToast('Registo efetuado com sucesso', 'Por favor confirme o seu email (' +resp.body.email+ ') antes de fazer Login.', 'success');
				this.closeLoginRegisterModal()
				this.resetForms();
				this.registerOnError = false;
			}
			else {
				for(const message of resp.body) {
					this.$showToast(`Erro - ${resp.status}`, message, 'danger');
				}
				this.registerOnError = true;
				this.showModal('login-register-form');
			}
			this.loading = false;
		},
		setTabModal() {
			if(this.registerOnError) {
				this.LoginModalTabIndex = 1;
			}
		},
		onCreate() {
			if(this.$route.name === 'home') {
				window.addEventListener('scroll', this.handleScroll);
			}
			this.scrolled = this.has_scrolled();
		},
		closeLoginRegisterModal() {
			this.$refs['login-register-form'].hide();
			this.LoginModalTabIndex = 0;
		},
		resetForms() {
			this.registerForm = {password: "", email: "", password_confirmation: "", agree: false, id_role: null, name: ""};
			this.loginForm = {password: "", email: "", remember_me: false};
		},
		showModal(ref) {
			this.$nextTick(() => {
				this.$refs[ref].show();
			});
		}
	},
	created () {
		this.onCreate();
	},
	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
	}
}
</script>
<style scoped lang="scss">
@import "@/assets/css/_custom.scss";
.my-navbar {
	background-color: transparent;
	top: 0;
	left: 0;
	z-index: 4;
	height: 140px;
	-moz-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.my-navbar a {
	margin-top: 30px;
}
.my-navbar.scrolled {
	height: 65px;
	background-color: #ffffff;
	-webkit-box-shadow: -1px 4px 33px -5px rgba(120,120,120,1);
	-moz-box-shadow: -1px 4px 33px -5px rgba(120,120,120,1);
	box-shadow: -1px 4px 33px -5px rgba(120,120,120,1);
}
.my-navbar.scrolled a {
	margin-top: 17px;
}
.my-navbar.scrolled a:not(.router-link-exact-active) {
	color: $primary !important;
}
.router-link-active, .router-link-exact-active {
	color: $darkgreen !important;
}

</style>